import React, { useEffect } from 'react'
import styles from './PageForMarketing.module.css'
import { hotjar } from 'react-hotjar'
import BannerForLaunch from 'components/BannerForLaunch'

type Props = {
  children: React.ReactNode,
  suppressBanners?: boolean,
}

const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID
const hotjarSnippetVersion = process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION

const PageForMarketing = ({ children, suppressBanners = false }: Props) => {
  useEffect(() => {
    // console.log('PAGE FOR MARKETING', hotjarId, hotjarSnippetVersion)
    if (hotjarId && hotjarSnippetVersion) {
      hotjar.initialize(Number(hotjarId), Number(hotjarSnippetVersion))
    }
  }, [])
  return <div className={styles.pageForMarketing}>
    {
      !suppressBanners && <BannerForLaunch />
    }
    {
      children
    }
  </div>
}

export default PageForMarketing
