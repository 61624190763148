import { truncate, first, isUndefined, unescape } from 'lodash'

export const smartTruncate = (stringToTruncate: string, length: number) => truncate(stringToTruncate, {
  length,
  'separator': /,?\.? +/
});

export const startsWithVowel = (stringToCheck: string) => {
  if (isUndefined(stringToCheck)) {
    return false
  }
  ['a', 'e', 'i', 'o', 'u'].includes(first(stringToCheck.split('')) as string)
}

export const unescapeTitle = (stringToDecode: string) => {
  const partiallyDecodedString = unescape(stringToDecode)
  const decodedString = partiallyDecodedString.replace(
    '&nbsp;', ' '
  )
  return decodedString
}

const emailAddressRegex = /(\S)+@(\S)+\.(\S){2,}/

export const isEmailAddress = (stringToCheck: string) =>
  !!stringToCheck.match(emailAddressRegex)

type MostCommonSubstringData = {
  string: string,
  count: number,
}

// Please don't run this with more than a few strings. Should only need a sample around 3-10 to get it
export const findCommonlySharedSubstring = (strings: string[]) => {
  const commonSubstrings = findCommonSubstrings(strings)
  console.log({ commonSubstrings })
  const mostCommon = determineHowCommon(strings, commonSubstrings)
  console.log({ mostCommon })
  const moreThanOneThird = mostCommon.count > Math.ceil(strings.length / 3)
  const longerThanAWord = mostCommon.string.indexOf(' ') > -1 // || mostCommon.string.length > 6
  if (moreThanOneThird && longerThanAWord && mostCommon.string !== first(strings)) {
    return mostCommon.string
  }
  return ''
}

const findCommonSubstrings = (strings: string[]): string[] => {
  const commonSubstrings = strings
    .map((string, index, array) => {
      if (index === 0) return undefined
      const str1 = array[index - 1]
      const str2 = string
      console.log('mappppp', str1, str2)
      const lcs = longestCommonSubstring(str1, str2)
      console.log({ lcs })
      return lcs
    })
    .filter(substr => substr !== '' && substr !== undefined) as string[]
  return commonSubstrings
}

const determineHowCommon = (strings: string[], commonSubstrings: string[]) => {
  const howCommon = commonSubstrings
    .reduce<MostCommonSubstringData>((mostCommon, substr) => {
      const stringsWithSubstr =
        substr
          ? strings.filter((string) => string.indexOf(substr) > -1)
          : []
      const count = stringsWithSubstr.length
      if (count > mostCommon.count) {
        return { string: substr || '', count }
      }
      return mostCommon
    }, { string: '', count: 0 })
  return howCommon
}

const longestCommonSubstring = (str1: string, str2: string): string => {
  console.log('lcsss', str1, str2)
  if (!str1 || !str2) { return '' }

  const str1Length = str1.length
  const str2Length = str2.length
  let maxLength = 0
  let beginIndx = 0 // relative to str1
  const num = [new Array(str2Length), ([] as number[]).fill(0, 0, -str2Length)] as [number[], number[]]
  for (let i = 0; i < str1Length; ++i) {
    const lastRow = 1 - i % 2
    const currentRow = num[1 - lastRow]
    for (let j = 0; j < str2Length; ++j) {
      if (str1[i] !== str2[j]) {
        currentRow[j] = 0
      } else {
        if (i === 0 || j === 0) {
          currentRow[j] = 1
        } else {
          currentRow[j] = 1 + num[lastRow][j - 1]
        }

        if (currentRow[j] > maxLength) {
          maxLength = currentRow[j]
          beginIndx = i - currentRow[j] + 1
          // if the current LCS is the same as the last time this block ran
        }
      }
    }
  }
  return str1.slice(beginIndx, maxLength + beginIndx)
}
