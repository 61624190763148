import Head from 'next/head'
import { unescapeTitle } from 'utilities/text'

type Props = {
  children: string,
}

const PageTitle = ({ children }: Props) =>
  <Head>
    <title>🦦 Blotter - { unescapeTitle(children) }</title>
  </Head>

export default PageTitle
