import styles from './BannerForLaunch.module.css'

const BannerForLaunch = () =>
  <div className={styles.bannerForLaunch}>
    <div className={styles.content}>
      <div className='uppercase text-2xl font-bold text-right leading-tighter'>Launch Sale</div>
      <div className='text-sm text-left'>
        <strong>33% OFF</strong> monthly plans. <strong>50% OFF!</strong> yearly plans. Limited spots and for a limited time only. Use&nbsp;code:&nbsp;<strong>EARLYBIRD33</strong>
      </div>
    </div>
  </div>

export default BannerForLaunch
