import MobileMenu from 'components/MobileMenu'
// import styles from './Navigation.module.css'
import { useEffect, useState } from 'react'

export type NavigationItem = {
  name: string,
  url: string,
  label: string,
}

export const navigation: NavigationItem[] = [
  {
    name: 'sharing',
    url: '/',
    label: 'Sharing',
  },
  {
    name: 'commenting',
    url: '/comments',
    label: 'Commenting',
  },
  // {
  //   name: 'pricing',
  //   url: '/pricing',
  //   label: 'Pricing',
  // },
]

const Navigation = () => {
  const [currentPath, setCurrentPath] = useState('/')
  useEffect(() => {
    setCurrentPath(window.location.pathname)
  }, [])
  return <>
    <nav className='gap-5 text-sm text-navy-500 hidden md:flex px-4 py-2 justify-end'>
      { navigation.map((item) =>
        <a key={item.name} href={item.url} className={`text-sm font-medium text-gray-500 ${
          item.url === currentPath
            ? 'underline underline-offset-4 cursor-default'
            : 'hover:text-gray-900 hover:underline hover:underline-offset-4'
          }`
        }>
          {item.label}
        </a>
      )}
      <a href={ currentPath === '/' ? '/sharing/start' : '/start' } className='px-4 py-1 -mt-1 bg-navy-500 text-sm text-white rounded-md'>Try for free</a>
    </nav>
    <MobileMenu
      navigation={navigation}
      currentPath={currentPath}
    />
  </>
}

export default Navigation
