import styles from './PageWithTailwind.module.css'
import PageTitle from 'components/PageTitle'
import PageDescription from 'components/PageDescription'
// import FontPreload from 'components/FontPreload'
// import Script from 'next/script'
// import getConfig from 'next/config'
// import dynamic from 'next/dynamic'
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
// import { useState } from 'react'

type Props = {
  children: React.ReactNode | string,
  description?: string,
  title: string,
  // suppressChat?: boolean,
  // isInternal?: boolean, // we should require description for any page that is not internal
}

const PageWithTailwind = ({
  children,
  description,
  title,
  // suppressChat = false,
}: Props) => {
  // const { publicRuntimeConfig } = getConfig()
  // const [isShowingTawk, setIsShowingTawk] = useState(false)
  return <>
    <PageTitle>{ title || 'Blotter' }</PageTitle>
    <PageDescription>{ description }</PageDescription>
    <div className={styles.pageWithTailwind}>{ children }</div>
    {/* { !suppressChat && <>
      <Script src={`${publicRuntimeConfig.blotterDomain}/static/js/chat.js`} strategy='afterInteractive' />
    </>} */}
    {/* { isShowingTawk && <div>
      <TawkMessengerReact
        propertyId='62671f8fb0d10b6f3e6f544f'
        widgetId='1g1hd6ijl'
      />
    </div> } */}
  </>
}

export default PageWithTailwind
