import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import BlotterIcon from 'components/Icons/BlotterIcon3'
import { NavigationItem } from 'components/Navigation'
import { Fragment } from 'react'
import styles from './MobileMenu.module.css'

type Props = {
  currentPath: string,
  navigation: NavigationItem[],
}

const MobileMenu = ({
  currentPath,
  navigation,
}: Props) =>
  <div className={styles.mobileMenu}>
    <header>
      <Popover className="relative">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          {/* <Popover.Group as="nav" className="hidden md:flex space-x-10">
            { navigation.map((item) =>
              <a key={item.name} href={item.url} className={`text-base font-medium text-gray-500 ${
                  item.url === CURRENT_PATH
                    ? 'underline underline-offset-8 cursor-default'
                    : 'hover:text-gray-900'
                }`
              }>
              {item.label}
              </a>
            )}
          </Popover.Group> */}
          {/* <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </a>
            <a
              href="#"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-navy-400 to-navy-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-600 hover:to-indigo-800 focus:ring-2 focus:ring-offset-2 focus:ring-navy-400 focus:outline-none"
            >
              Sign up
            </a>
          </div> */}
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <BlotterIcon name='menu_mobile' width={96} height={96} />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid grid-cols-1 gap-7">
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-1 gap-4">
                  { navigation.map((item) =>
                    <a key={item.name} href={item.url} className={`text-base font-medium text-gray-500 ${
                        item.url === currentPath
                          ? 'underline underline-offset-8 cursor-default'
                          : 'hover:text-gray-900'
                      }`
                    }>
                    {item.label}
                    </a>
                  )}
                </div>
                <div className="mt-6">
                  <a
                    href="/login"
                    className="w-full flex items-center justify-center bg-gradient-to-r from-navy-400 to-navy-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-600 hover:to-indigo-800 focus:ring-2 focus:ring-offset-2 focus:ring-navy-400 focus:outline-none"
                  >
                    Sign up now &raquo;
                  </a>
                  {/* <a
                    href="#"
                    className="w-full flex items-center justify-center bg-gradient-to-r from-navy-400 to-navy-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-600 hover:to-indigo-800 focus:ring-2 focus:ring-offset-2 focus:ring-navy-400 focus:outline-none"
                  >
                    Sign up
                  </a>
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?
                    <a href="#" className="text-gray-900">
                      Sign in
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  </div>

export default MobileMenu
