import { Icon } from './Types'

const BlotterIcon = ({
  name,
  width = 48,
  height = 48,
  className = undefined
}: Icon & { name: string }) => {
  return <>
    <svg width={ width } height={ height } viewBox="0 0 577 579" fill="none" className={ className }>
      <path d="M288.5 560.002C437.927 560.002 559 438.637 559 289.001C559 139.365 437.927 18 288.5 18C139.073 18 18 139.365 18 289.001C18 438.637 139.073 560.002 288.5 560.002Z" fill="#E1DDF4" stroke="#52557A" strokeWidth="36"/>
      <mask id={`mask0_${name}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="15" y="15" width="547" height="549">
      <path d="M288.5 552.502C433.771 552.502 551.5 434.509 551.5 289.001C551.5 143.493 433.771 25.5 288.5 25.5C143.229 25.5 25.5 143.493 25.5 289.001C25.5 434.509 143.229 552.502 288.5 552.502Z" fill="#D9F2F2" stroke="#8991D4" strokeWidth="21"/>
      </mask>
      <g mask={`url(#mask0_${name})`}>
      <path d="M187.383 207.473L187.382 207.386C187.286 176.758 199.544 153.905 217.881 138.48C236.408 122.895 261.527 114.603 287.171 114.277C312.809 113.951 338.318 121.596 357.627 137.037C376.69 152.281 390.158 175.454 391.683 207.434L391.674 207.679C391.661 208.069 391.641 208.648 391.616 209.408C391.565 210.926 391.491 213.163 391.4 216.043C391.217 221.803 390.965 230.135 390.684 240.433C390.124 261.028 389.451 289.492 389.002 320.967C388.106 383.803 388.097 459.026 391.713 507.504C400.726 628.316 528.319 724.136 660.642 696.948L662.613 696.542L664.19 695.296C679.25 683.388 689.08 670.881 693.808 657.478C698.585 643.935 697.841 630.355 693.318 617.008C684.525 591.061 660.98 564.848 633.757 537.948C624.812 529.109 615.417 520.148 605.811 510.986C585.519 491.632 564.285 471.379 544.355 449.473C517.796 420.281 494.328 388.932 479.8 354.593C646.593 343.858 777.399 456.067 828.46 583.198C854.529 648.106 859.424 716.037 838.393 772.673C817.509 828.914 770.675 875.111 690.83 896.512C532.174 931.581 405.822 891.645 319.472 814.73C232.877 737.596 185.887 622.717 187.381 507.043C188.268 438.357 188.268 363.431 188.047 305.694C187.936 276.821 187.77 252.239 187.631 234.875C187.562 226.192 187.499 219.314 187.454 214.605C187.432 212.25 187.414 210.438 187.401 209.215L187.387 207.826L187.383 207.473Z" fill="white" stroke="#52557A" strokeWidth="17"/>
      <path d="M232.288 114.783C240.621 121.957 229.891 129.11 219.501 139.5C209.111 149.89 202.997 159.581 194.664 152.408C186.331 145.234 187.998 130.996 198.387 120.607C208.777 110.217 223.955 107.61 232.288 114.783Z" fill="#52557A"/>
      <path d="M345.178 115.783C336.845 122.957 347.575 130.11 357.965 140.5C368.355 150.89 374.469 160.581 382.802 153.408C391.135 146.234 389.468 131.996 379.078 121.607C368.689 111.217 353.511 108.61 345.178 115.783Z" fill="#52557A"/>
      <path d="M188.575 387.17C188.575 314.5 210.28 292.025 241.378 280.776C326.499 259.5 251.499 354 188.575 387.17Z" stroke="#52557A" strokeWidth="17" strokeLinecap="round"/>
      <path d="M387.774 387.17C387.774 314.5 366.068 292.025 334.97 280.776C249.849 259.5 324.85 354 387.774 387.17Z" stroke="#52557A" strokeWidth="17" strokeLinecap="round"/>
      <path d="M257.068 233.163L198.718 248.747" stroke="#52557A" strokeWidth="6" strokeLinecap="round"/>
      <path d="M320.718 233.163L379.067 248.747" stroke="#52557A" strokeWidth="6" strokeLinecap="round"/>
      <path d="M270.363 242.649L229.739 260.943" stroke="#52557A" strokeWidth="6" strokeLinecap="round"/>
      <path d="M223.504 194.714C223.662 193.055 223.971 191.379 224.658 189.798C225.667 187.463 227.111 185.955 230.296 185.235C235.703 184.017 239.989 187.437 242.045 190.973C244.035 194.407 244.736 198.277 244.978 202.024C245.05 203.019 245.065 204.015 245 205.012C244.736 208.886 244.343 214.418 239.025 216.191C233.187 218.134 228.452 213.683 226.486 209.926C224.051 205.269 223.022 199.75 223.504 194.714Z" fill="#52557A"/>
      <path d="M288.064 241.698C287.561 241.529 287.065 241.312 286.625 240.986C285.974 240.505 285.612 239.949 285.618 238.91C285.632 237.148 287 236.068 288.243 235.686C289.45 235.318 290.699 235.38 291.877 235.575C292.19 235.625 292.5 235.692 292.804 235.784C293.984 236.146 295.669 236.667 295.834 238.441C296.015 240.389 294.295 241.534 292.989 241.872C291.372 242.29 289.588 242.21 288.064 241.698Z" fill="#52557A"/>
      <path d="M354.708 194.714C354.55 193.055 354.241 191.379 353.554 189.798C352.545 187.463 351.101 185.955 347.916 185.235C342.509 184.017 338.223 187.437 336.167 190.973C334.177 194.407 333.476 198.277 333.234 202.024C333.162 203.019 333.147 204.015 333.212 205.012C333.476 208.886 333.869 214.418 339.187 216.191C345.025 218.134 349.76 213.683 351.726 209.926C354.16 205.269 355.19 199.75 354.708 194.714Z" fill="#52557A"/>
      <path d="M305.711 224.27C311.007 223.067 317.534 221.333 320.354 216.619C326.892 205.697 308.316 201.487 300.507 200.648C291.924 199.725 283.057 199.613 274.597 201.347C271.377 202.019 268.213 202.97 265.243 204.298C261.726 205.865 256.988 208.046 256.898 212.13C256.871 213.459 257.013 214.773 257.423 216.046C258.129 218.27 259.633 220.158 261.627 221.57C265.94 224.605 271.773 225.486 277.049 225.906C285.959 226.71 288.119 228.59 291 234C293.074 228.409 297.28 226.237 305.711 224.27Z" fill="#52557A"/>
      <path d="M313.76 242.649L354.383 260.943" stroke="#52557A" strokeWidth="6" strokeLinecap="round"/>
      </g>
    </svg>
  </>
}

export default BlotterIcon
